<template>

  <component
    :is="tag"
    v-click-outside="hide"
    class="dropdown"
    :class="{ show: isShowing }"
  >
    <a ref="dropdown" @click="toggle" role="button" aria-haspopup="true" aria-expanded="false">
      <slot name="text" />
    </a>
    <div ref="menu" class="dropdown-menu new-flow" :class="{ show: isShowing }">
      <slot name="items" />
    </div>
  </component>
</template>

<script>
import * as Popper from '@popperjs/core';
// import Popper from 'popper.js';

export default {
  name: 'Dropdown',
  props: {
    tag: {
      type: String,
      default: 'div',
    },
  },
  // props: {
  //   variant: {
  //     type: String,
  //     default: 'link',
  //   },
  // },
  data() {
    return {
      isShowing: false,
      popper: null,
    };
  },
  computed: {
    visible() {
      return this.$refs.current?.visible ?? false;
    },
  },
  created() {
    this.$root.$on('click-outside', this.clickOutside);
  },
  methods: {
    toggle() {
      return this.isShowing ? this.hide() : this.show();
    },
    show() {
      this.isShowing = true;
      this.createPopper();

      this.$refs.dropdown.focus();
      this.$refs.dropdown.setAttribute('aria-expanded', true);
    },
    hide() {
      this.isShowing = false;
      if (this.popper) {
        this.popper.destroy();
      }

      this.$refs.dropdown.setAttribute('aria-expanded', false);
    },
    handleOverflow(val) {
      for (
        let index = 0;
        index < document.querySelectorAll('.table-responsive').length;
        index += 1
      ) {
        document.getElementsByClassName('table-responsive')[
          index
        ].style.overflowX = val;
      }
    },
    createPopper() {
      const popperConfig = {
        placement: 'left',
        modifiers: [
          {
            name: 'preventOverflow',
            options: {
              boundary: 'clippingParents',
            },
          },
          {
            name: 'offset',
            options: {
              offset: [0, 2],
            },
          },
        ],
      };
      this.popper = Popper.createPopper(
        this.$refs.dropdown,
        this.$refs.menu,
        popperConfig,
      );
    },
  },
};
</script>

<style scoped lang="scss">

.dropdown {
  display: inline-flex;
}

::v-deep .dropdown-toggle-no-caret::after {
  display: none !important;
}
</style>
